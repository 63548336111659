<template>
  <div :class="(isFixed?'tofixed nav container':'toabsolute nav container') + ' ' + (!$route.path.includes('/home/index') ? 'w100' : '')" id="boxFixed">
    <!-- <router-link :to="{name:'首页'}" tag="li" active-class="active">首页</router-link>
      <router-link :to="{ name:'招采信息' }" tag="li" active-class="active">招采信息</router-link>
      <router-link :to="{name:'通知公告'}" tag="li" active-class="active">通知公告</router-link>
      <router-link :to="{name:'最新动态'}" tag="li" active-class="active">最新动态</router-link>
      <router-link :to="{name:'政策法规'}" tag="li" active-class="active">政策法规</router-link>
      <router-link :to="{name:'下载专区'}" tag="li" active-class="active">下载专区</router-link>
    <router-link :to="{name:'服务指南'}" tag="li" active-class="active">服务指南</router-link>-->
    <NavItem :list="navData" class="container" :leave="1" />
  </div>
</template>

<script>
import moduleApi from "@/api/module";
import NavItem from "./NavItem";

export default {
  components: { NavItem },
  data() {
    return {
      navData: [],
      isFixed:false,
      offsetTop:0
    };
  },
  async created() {
    let { data } = await moduleApi.findNav();
    this.navData = data;

    // 将获取到的导航数据存到vuex(粗发mutations里面的getNav函数来改变nav的数据)
    this.$store.commit("getNav", this.navData);
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
    this.offsetTop = document.querySelector('#boxFixed').offsetTop
  },
  methods:{
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      this.isFixed = scrollTop > this.offsetTop ? true : false; 
    }
  }
}
</script>

<style lang="less" scoped>
.w100{
  width: 100%;
}
.tofixed{
  position: fixed;
  top: 0;
  z-index: 50;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.toabsolute{
  position: absolute;
  top: 82px;
  z-index: 50;
  left: 50%;
  transform: translateX(-50%);
}
.nav {
  background: #F36E31;
  font-size: 16px;
}
</style>
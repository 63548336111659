<template>
  <ul class="nav_list">
    <li
      tag="li"
      :class="{'active': $route.name.includes(item.moduleName) }"
      v-for="item in list" :key="item.id"
      @click.stop="runModule(item)"
      @mouseover.stop="leave === 1? showSecond(item): ''"
      @mouseleave.stop="leave === 1? show = false: ''"
    >
      <div
        :class="{'hover_blue': leave !== 1, 'blue_color': 
        ( $route.name === '招采信息' && $route.query.type === item.moduleName && $route.query.isGov*1 === (towLevelName === '工程建设' ?1: 2) )
        ||
        ($route.name === '服务指南' && $route.query.type * 1 === typeValue[item.moduleName])
      }">
        {{item.moduleName}}
      </div>

      <template v-if="item.children && item.children.length > 0">
        <transition name="fade">
        <div
          v-show="(show && item.id === parentId) || leave === 2"
          class="nav_con"
          :class="{'has_child': leave === 1 && item.children[0].children.length,
            'second_nav': leave === 1
          }"
        >
        <!-- has_child 是否有三级 -->
          <NavItem
          :list="item.children" 
          class="children_ul" 
          :leave="leave + 1" 
          :oneLevelName="leave === 1? item.moduleName: oneLevelName" 
          :towLevelName="leave === 2? item.moduleName: towLevelName"/>
          
        </div>
        </transition>
      </template>

    </li>
  </ul>
</template>
<script>

export default {
  name: 'NavItem',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    // 是几级菜单
    leave: {
      type: Number,
      default: 0
    },
    oneLevelName: {
      type: String,
      default: ''
    },
    towLevelName: {
      type: String,
      default: ''
    }
  },
  data() {
    return{
      typeValue: {
        '办事指南': 1,
        '办事流程': 2,
        '管理规定': 3,
        '数字证书办理': 4,
      },
      show: false,
      parentId: 0
    }
  },
  methods:{
    showSecond ({id}) {
      this.parentId = id
      this.show = true
    },
    runModule ({moduleName}) {
      let leave = this.leave
      let oneLevelName = this.oneLevelName
      let towLevelName = this.towLevelName
      let runObj = {name: moduleName}

      if (leave === 1) {
        if (moduleName === '招采信息') {
          runObj = {
            ...runObj,
            query: {
              isGov: 1, type: '全部'
            }
          }
        } else if (moduleName === '服务指南') {
          runObj = {
            ...runObj,
            query: {
              type: 1
            }
          }
        }
      } else {
        if (oneLevelName === '招采信息') {
          runObj = {
            name: oneLevelName,
            query: {
              isGov: towLevelName === '工程建设' || moduleName === '工程建设'? 1: 2,
              type: leave === 3? moduleName: '全部'
            }
          }
        } else if (oneLevelName === '服务指南') {
          runObj = {
            name: oneLevelName,
            query: {
              type: this.typeValue[moduleName]
            }
          }
        }
      }
      this.$router.push({
        ...runObj
      })
    }
  }
}
</script>
<style lang='less'>
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  max-height: 500px;
}
.fade-enter, .fade-leave-to {
  opacity: .1;
  max-height: 0;
}

// 一级ul
ul.nav_list.container {
  display: flex;
  justify-content: space-between;
  // 一级li
  @width: 90px;
  &>li{
    // width: @width;
    flex: 1;
    line-height: 44px;
    height: 44px;
    text-align: center;
    cursor: pointer;
    position: relative; //方便于子菜单的绝对定位
    transition: all 0.3s;
    border-right: 1px solid #FF864F;
    font-size: 16px;
    // li的激活颜色
    &:hover, &.active {
      background: #414085;
      border-right: 1px solid #414085;
    }
    
    @notchild_secondWidth: 230px;
    &>.nav_con{
      position: absolute;
      z-index: 11;
      top: 27px;
      left: 0;
      overflow: hidden;
      // 二级ul
      &>.children_ul {
        margin-top: 17px;
        background: #ECECF1;
        padding: 10px 0 3px 18px;
        display: flex;
        flex-wrap: wrap;
        width: @notchild_secondWidth;
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-bottom: 6px solid #ECECF1;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          position: absolute;
          top: 11px;
          left: 76px;
        }
        @blueColor: #F36E31;;
        .blue_color {
          color: @blueColor;
        }
        .hover_blue:hover {
          color: @blueColor;
        }
        li {
          line-height: 20px;
          width: 106px;
          color: #494A4C;
          .hover_blue {
            margin-bottom: 8px;
          }
          // padding: 0 10px;
        }
      }
    }

    // 有三级的二级ul
    &>.nav_con.has_child{
      @hasChildSecondWidth: 343px;
      left: 0px;
      &>.children_ul {
        width: @hasChildSecondWidth;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: 10px;
        &>li {
          display: block;
          width: auto;
          color:#20201E;
          font-size: 16px;
          font-weight: bold;
          margin-top: 6px;
          margin-bottom: 4px;
        }
        // 三级ul
        .children_ul {
          display: flex;
          flex-wrap: wrap;
          li {
            display: inline-block;
            width: 107px;
            height: 20px;
            color: #494A4C;;
            font-weight: 400;
            margin-bottom: 4px;
          }
        }
      }
    }

  }
}
ul.nav_list {
  // 导航里面的每一个菜单
  li {
    color: white;
    font-size: 14px;
    text-align: left;
  }
}
</style>
<template>
  <div class="home">
    <div>

    <!-- 顶部展示图 -->
    <TopPng imgModule="7" v-if="$route.path.includes('/home/index')"></TopPng>

    <rightAD v-if="!$route.path.includes('/home/index')"></rightAD>
    
    <!-- 公告及导航栏 -->
    <NoticeNav></NoticeNav>
    
    <Banner class="Banner" v-if="$route.path.includes('/home/index')"></Banner>

    <!-- <div class="header-nav">
      <Header></Header>
  
    </div> -->
    <!-- <Nav></Nav> -->
    <div :class="$route.path.includes('/home/index')? 'header-box': 'header-inside'">
      <section :class="$route.path.includes('/home/index')?'':'inside-banner'">
        <InsideBanner v-if="!$route.path.includes('/home/index')"></InsideBanner>
        <!-- <div class="bgimg" v-if="$route.path.includes('inform-notice') || 
            $route.path.includes('/notice-detail')
            ">
          <img id="img" src="@/assets/images/notice/tzgg_nybaaer.jpg" 
          />
          <canvas id="canvas" width="192px" height="20px" style="display: none;"></canvas>
        </div>
        <div style="background: #493f84" v-if="$route.path.includes('download-section')">
          <img src="@/assets/images/download/xzzq_nybaaer.jpg"  />
        </div>
        <div style="background: #14a6e2" v-if="$route.path.includes('trade-info') || 
              $route.path.includes('tender-notice-detail') ||
              $route.path.includes('publicity') || 
              $route.path.includes('addendum') ||
              $route.path.includes('abnormal')
            ">
          <img src="@/assets/images/zc/招采信息.jpg" 
          />
        </div>
        <div style="background: #05b98a" v-if="$route.path.includes('directory') || $route.path.includes('derectory')">
          <img 
            src="@/assets/images/service/fwzn_nybaaer.jpg" 
          />
        </div>

        <div style="background: #2c4cf9" v-if="$route.path.includes('policy')">
          <img 
            src="@/assets/images/zcfg/zcfg_nybaaer.jpg" 
          />
        </div>
         <div style="background: #fe9402" v-if="$route.path.includes('news')">
          <img 
            src="@/assets/images/zxdt/zxdt_nybaaer.jpg"
          />
         </div> -->
      </section>
      <!-- 面包屑 -->
      <bread-crumb v-if="$route.meta.bread"></bread-crumb>
    </div>
    <!-- 轮播图（只有首页有） -->

    <!-- 登录多端的入口（只有首页有） -->
    <!-- <Entrance v-if="$route.path.includes('/home/index')"></Entrance> -->

    <!-- 路由页面（局部更新其他页面） -->
    <keep-alive>
      <router-view :key="$route.fullPath">
        
      </router-view>
    </keep-alive>
      
    
    <!-- <Footer></Footer> -->
    
    <!-- 回到顶部 -->
    <!-- <back-top></back-top> -->
    <BackTop></BackTop>
    <!-- <InterActive /> -->
  </div>
  
  <NewFooter></NewFooter>
  </div>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import TopPng from "@/components/Layout/TopPng.vue";
import NoticeNav from "@/components/Layout/NoticeNav.vue";
import NewFooter from "@/components/Layout/NewFooter.vue";

import Nav from "@/components/Layout/Nav.vue";
import Footer from "@/components/Layout/Footer.vue";
import InterActive from "@/components/Index/InterActive.vue";

import Banner from "@/components/Layout/Banner.vue";
import InsideBanner from "@/components/InsideBanner"

import BackTop from "@/components/Layout/BackTop"
// import Entrance from "@/components/Layout/Entrance.vue";
import rightAD from "@/components/Layout/rightAD"

export default {
  components: { rightAD, BackTop, NewFooter, NoticeNav, TopPng, Banner, InsideBanner },
  data() {
    return {
    }
  },
  created() {
  },

};
</script>

<style lang="less" scoped>
.home {
  position: relative;
  // overflow: hidden;
  min-width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .bgimg {
    overflow: hidden;

    img {
      z-index: 0;
    }
  }
  .bgimg::before {
    content: '';
    position:absolute;
    width: 100%;
    height: 200px;
    background-image: url('https://test-dzztb-api.ygzdztb.com/mh/file/images/null/20230220/202302201731239365.jpg'); 
    background-size: 1000px;
    background-position: center center;
    z-index: -1;
    // background: #000;
    filter: blur(20px);
  }
  .header-box{
    // background: url('../assets/images/newImages/banner.png') no-repeat center;

  }
  .header-inside {

    .nav {
      width: 100%;
    }
  }
  .inside-banner {
    div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      height: 200px;
    }
  }
  .header-nav{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .Banner{
    position: relative;
    z-index: 1;
  }
}
</style>
<template>
<!-- 右侧导航栏 -->
  <div class="inter-active">
    <div class="fixed-box">
      <ul class="fixed-box-ul">
        <li v-for="v in exchangeList" :key="v.icon" @click="change(v)">
          <p class="icon">
            <img :src="v.imgUrl" alt="">
          </p>
          <p class="title">{{ v.title }}</p>
        </li>
      </ul>
    </div>
    <el-dialog title="请扫描二维码" :lock-scroll="false" :modal="true" width="30%" style="text-align: center;" :modal-append-to-body="false" :visible.sync="dialogTableVisible">
      <img src="@/assets/images/qrcode.png" alt="请扫描二维码">
    </el-dialog>
  </div>
</template>

<script>
  export default {
    mounted() {
      window.onscroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop >= 400) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }
      };
    },
    data(){
      return{
        dialogTableVisible: false,
        exchangeList: [
        { title: "反馈信箱", 
          icon: "iconfont icon-youjian",  
          imgUrl: require("../../assets/images/newImages/icon-zxzx@2x.png") 
        },
        { title: "在线咨询", 
          icon: "iconfont icon-zaixianzixun" ,
          imgUrl: require("../../assets/images/newImages/icon-fkxx@2x.png") 
        },
        { title: "下载专区", 
          icon: "iconfont icon-xiazaizhuanqu" ,
          imgUrl: require("../../assets/images/newImages/icon-zjdc@2x.png") 
        },
        { title: "征集调查", 
          icon: "iconfont icon-fangdajing",
          imgUrl: require("../../assets/images/newImages/icon-xzzq@2x.png") 
        },
        { title: "返回顶部", 
          icon: "iconfont icon-fangdajing",
          imgUrl: require("../../assets/images/newImages/icon-db@2x.png") 
        }
      ],
      isShow: false,
      timeId: null
      }
    },
    beforeDestroy() {
      console.log("BackTop组件销毁了");
      // 清除window上的东西，如定时器、滚动事件
      window.onscroll = null;
      clearInterval(this.timeId);
    },
    methods:{
      change(val){
        switch (val.title) {
          case '在线咨询':
          this.dialogTableVisible = true
            break;
          case '返回顶部':
            this.backTop()
            break;
          case '下载专区':
            this.goDownLoad()
            break;
          case '征集调查':
            window.open('https://www.wjx.cn/vm/mBMmVPx.aspx#')
            break;
        }
      },
      goDownLoad(){
        this.$router.push({path: 'download-section'})
      },
      backTop() {
        clearInterval(this.timeId); //防止多次点击而启动多个定时器
        this.timeId = setInterval(() => {
          if (document.documentElement.scrollTop > 0) {
            document.documentElement.scrollTop -= 100;
          } else {
            clearInterval(this.timeId);
          }
        }, 10);
      }
    }
  }
</script>

<style lang="less" scoped>
.inter-active{
  position: fixed;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  .fixed-box{
    width: 60px;
  }
  .fixed-box-ul{
    display: flex;
    flex-direction: column;
    color: #20201E;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    >li{
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 10px;
      cursor: pointer;
      &:hover{
        color: #f36e31;
      }
      .icon{
        box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, .1);
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        background: #fff;
        border-radius: 8px;
        img{
          height: 24px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="banner">
    <el-carousel :interval="4000" arrow="never" :indicator-position="list.length === 1 ? 'none' : ''" height="446px">
      <el-carousel-item class="banner-box" v-for="v in list" :key="v.id">
        <img @click="jump(v)" :src="baseURL + v.attachFilePath" alt="图签加载失败" />
      </el-carousel-item>
    </el-carousel>
    <div class="container">
      <div class="login-box">
        <div class="logo-img ">
          <img src="@/assets/images/new-ui/index_load_logo@2x.png" alt="">
        </div>
        <p class="hello">
          hi，欢迎来到阳光臻达电子招投标交易平台
        </p>
        <div class="golist">
          <div class="zbf" @click="goLink(1)">
            <img src="@/assets/images/new-ui/icon-zbf@2x.png" alt="">
            招标方登录
          </div>
          <div class="tbf" @click="goLink(2)">
            <img src="@/assets/images/new-ui/ic_tbf@2x.png" alt="">
            投标方登录
          </div>
          <div class="dlf" @click="goLink(3)">
            <img src="@/assets/images/new-ui/ic_dlf@2x.png" alt="">
            代理方登录
          </div>
          <div class="dlf" @click="goLink(4)">
            <img src="@/assets/images/new-ui/ic_dlf@2x (1).png" alt="">
            专家库登录
          </div>
        </div>
        <div class="problem">
          <div class="problem-title">
            <div class="left">
              常见问题
            </div>
            <div class="right" @click="more">
              <div>
                查看更多
              </div>
              <div class="icon"></div>
            </div>
          </div>
          <ul class="problem-list" v-loading="loading">
            <li @click="goDetail(item)" v-for="item of listData" :key="item.id">{{item.title}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      list: [],
      online: process.env.NODE_ENV === 'production',
      listData: []
    };
  },
  created() {
    this.getBannerList()
    this.renderList()
  },
  methods: {
    more() {
      this.$router.push({
        name: '帮助中心',
        query: {
          type: '2',
        }
      })
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '帮助中心文章详情',
        query: {
          id: item.id,
        }
      })
      this.clickNum(item.id)
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: 1,
          size: 3,
          module: '6', //(1.通知公告 2.最新动态 3政策法规 6 帮助中心)
          serviceType: '2',
          sort: "asc",
          sortFiled: "sort",
          title: this.serchValue,
          contentType: '1'
        });
        if (code === 200) { 
          this.listData = [...data.records]
        }
      } finally {
        this.loading = false
      }
    },
    goLink(val) {
      let { redirect } = this.$route.query;
      let fullPath = this.$route.fullPath;
      let queryPath = fullPath.split("?")[1];
      let redirectUrl = redirect ? "&" + queryPath : "";
      if (val === 1) {
        location.href = `https://${this.online?'':'test-'}dljg.ygzdztb.com/#/login?role=${1}${redirectUrl}`;
      } else if (val === 2) {
        location.href = `https://${this.online?'':'test-'}tbr.ygzdztb.com/#/login?role=${3}${redirectUrl}`;
      } else if (val === 3) {
        location.href = `https://${this.online?'':'test-'}dljg.ygzdztb.com/#/login?role=${2}${redirectUrl}`;
      } else if (val === 4) {
        window.open(this.online ? 'https://expdb.ygzdztb.com' : 'https://test-sphy.ygzdztb.com')
      }
    },
    async jump(item) {
      if (item.url) {
        await indexApi.imageClick({id: item.id})
        window.open(item.url, '_blank')
      }
    },
    async getBannerList(){
      let { code, data, message } = await indexApi.findBanner({
      module: 1 //1顶部轮播图，2公告轮播图
      });
      this.list = data;
    }
  },
  computed: {
    ...mapState(["baseURL"])
  }
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  .container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-direction:row-reverse;
    height: 0;
    .login-box {
      width: 391px;
      height: 414px;
      background: linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%);
      box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
      border-radius: 12px 12px 12px 12px;
      border: 2px solid #FFFFFF;
      padding: 16px 24px;
      margin-top: 16px;
      .logo-img {
        text-align: center;
        >img {
          width: 40px;
        }
      }
      .hello {
        color: #333;
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 16px;
      }
      .golist {
        display: flex;
        flex-wrap:wrap;
        justify-content: start;
        div {
          color: #666;
          font-size: 16px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          background: #fff;
          box-shadow: 8px 8px 16px 1px rgba(0,0,0,0.05);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #FFFFFF;
          transition: all .2s ease;
          height:77px;
          cursor: pointer;
          img {
            width: 44px;
          }
          &:nth-child(1) {
            flex: 1;
            &:hover {
              background: rgba(11,125,245,0.1);
              border: 1px solid #0A7DF5;
            }
          }
          &:nth-child(2) {
            flex: 1;
            margin: 0 12px 12px 12px;
            &:hover {
              background: rgba(0,195,128,0.1);
              border: 1px solid #00C380;
            }
          }
          &:nth-child(3) {
            flex: 1;
            &:hover {
              background: rgba(243,110,49,0.1);
              border: 1px solid #F36E31;
            }
          }
          &:nth-child(4) {
            width: 100%;
            height: 64px;
            flex-direction: row;
            img {
              margin-right: 12px;
            }
            &:hover {
              background: rgba(133, 79, 254,0.1);
              border: 1px solid #854ffe;
            }
          }
        }
      }
      .problem {
        margin-top: 24px;
        .problem-title {
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            color: #333;
            font-size: 16px;
            font-weight: bold;
          }
          .right {
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: #999;
            align-items: center;
            cursor: pointer;
            transition: color 0.2s;
            div {
              line-height: 24px;
            }
            &:hover {
              color: #F36E31;
              .icon {
                background-image: url(~@/assets/images/new-ui/ic_more_hover.svg);
              }
            }
            .icon {
              background-image: url(~@/assets/images/new-ui/ic_more.svg);
              width: 24px;
              height: 24px;
              background-size: 24px 24px;
              display: block;
              
            }
          }
        }
        .problem-list {
          >li {
            position: relative;
            padding-left: 15px;
            color: #333;
            font-size: 14px;
            margin-bottom: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover::after {
              border: 2px solid #F36E31;
            }
            &::after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border: 2px solid #999999;
              border-radius: 6px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              transition: border-color .3s;
            }
          }
        }
      }
    }
  }
  /deep/ .el-carousel__indicators{

    .el-carousel__button {
      width: 64px;
      height: 6px;
      background: #333333;
      border-radius: 8px 8px 8px 8px;
      margin-right: 12px;
    }
    // display: none;
    .is-active {
      .el-carousel__button {
        background: #fff;
      }
    }
  }
  .banner-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-carousel {
    .el-carousel__item {
      img {
        // width: 100%;
        height: 446px;
      }
    }
  }
}
</style>
<template>
  <div :class="isFixed ? 'notice-nav' : 'notice-nav'" id="boxFixed">
    <div :class="isFixed ? 'tofixed' : ''">
      <div class="notice">
        <div class="container">
          <div class="left-notice">
            <img src="@/assets/images/new-ui/ic_gg@2x.png" alt="">
            <el-carousel direction="vertical" height="44px" indicator-position="none" arrow="never">
              <el-carousel-item v-for="item of listData" :key="item.id">
                <h3 class="small"  @click="goDetail(item)">{{item.title}}</h3>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="phone">
            <img src="@/assets/images/new-ui/ic_tel@2x.png" alt="">
            <span>咨询热线</span>
            <span>400-883-9300</span>
          </div>
        </div>
      </div>
      <div class="nav container">
        <div class="logo">
          <el-link :underline="false" href="/home/index">
            <img src="@/assets/images/newImages/logo_标准@2x.png" alt="图片加载失败" class="logo" />
          </el-link>
        </div>
        <div class="right-nav">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item @click="goRoute(item)" v-for="item of navList" :key="item.id" :index="item.moduleName">
              {{ item.moduleName }}
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import moduleApi from "@/api/module";
import indexApi from "@/api/index.js";
export default {
  data() {
    return {
      activeIndex: '首页',
      isFixed:false,
      offsetTop:0,
      navList: [],
      listData: []
    }
  },
  watch: {
    "$route.path"(newVal, oldVal) {
      this.setActiveIndex();
    }
  },
  async created() {
    let { data } = await moduleApi.findNav();
    this.navList = data;
    if (this.$route.meta.bread) {
      let obj = this.navList.find(item => this.$route.meta.bread[0].title.includes(item.moduleName))
      this.activeIndex = obj.moduleName
    } else {
      let obj = this.navList.find(item => this.$route.name.includes(item.moduleName))
      this.activeIndex = obj.moduleName
    }
    this.renderList()
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
    this.offsetTop = document.querySelector('#boxFixed').offsetTop + 112
  },
  methods:{
    setActiveIndex() {
      if (this.$route.meta.bread) {
        let obj = this.navList.find(item => this.$route.meta.bread[0].title.includes(item.moduleName))
        this.activeIndex = obj.moduleName
      } else {
        let obj = this.navList.find(item => this.$route.name.includes(item.moduleName))
        this.activeIndex = obj.moduleName
      }
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '最新资讯详情',
        query: {
          id: item.id
        }
      })
      this.clickNum(item.id)
    },
    async renderList() {
      let { code, data, message } = await indexApi.findInformOrNewsList({
        page: 1,
        size: 5,
        module: '1', //(1.通知公告 2.最新动态 3政策法规)
        sort: "asc",
        sortFiled: "sort",
        contentType: '1'
      });
      if (code === 200) {
        this.listData = [...data.records]
      }
    },
    goRoute(item) {
      if (item.moduleName === '招采信息') {
        this.$router.push({name: item.moduleName, query: {
              isGov: 1, type: '全部', n: '1'
            }})
        return
      }
      if (item.moduleName === '帮助中心') {
        this.$router.push({name: item.moduleName,query:{type: '1',contentType: '1'}})
        return
      }
      this.$router.push({name: item.moduleName,query:{type: '1'}})
    },
    handleSelect(key) {
      this.activeIndex = key
    },
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      this.isFixed = scrollTop > this.offsetTop ? true : false; 
    }
  }
}
</script>


<style lang="less" scoped>
  .notice-nav {
    background: #fff;
    width: 100%;
    box-shadow: 0px 4px 16px 1px rgba(0,0,0,0.05);
    min-height: 112px;
    z-index: 50;
    .notice {
      height: 44px;
      background: #f4f4f4;
      .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        .left-notice {
          display: flex;
          align-items: center;
          height: 100%;
          width: 400px;
          img {
            width: 24px;
            margin-right: 8px;
          }
          .el-carousel {
            flex: 1;
            .el-carousel__item {
              line-height: 44px;
              h3 {
                width: 100%;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                color: #333;
                font-size: 14px;
                transition: all 0.15s;
                &:hover {
                  color: #F36E31;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .phone {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 14px;
          img {
            width: 24px;
            margin-right: 4px;
          }
          span:nth-child(2) {
            margin-right: 14px;
            color: #666;
          }
          span:nth-child(3) {
            color: #F36E31;
            font-weight: bold;
          }
        }
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 68px;
      img {
        width: 202px;
      }
      .right-nav {
        .el-menu-demo {
          border-bottom: none;
          .el-menu-item {
            height: 30px;
            line-height: 30px;
            padding: 0;
            margin-left: 80px;
            font-size: 16px;
            color: #999;
            font-weight: 400;
            border-bottom: none !important;
            transition: background-color .3s,color .3s;
            &::after {
              content: '';
              position: absolute;
              width: 0;
              height: 3px;
              background: #F36E31;
              border-radius: 4px 4px 4px 4px;
              bottom: -2px;
              left: 50%;
              transform-origin: center;
              transition: width 0.2s ease,left 0.2s ease,transform 0.2s ease;
            }
            &:hover::after {
              width: 32px;
              left: 50%;
              transform: translateX(-50%);
            }
            &:hover {
              color: #333;
              border-bottom: none !important;
            }
          }
          .is-active {
            color: #333;
            border-bottom: none;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              width: 32px;
              height: 3px;
              background: #F36E31;
              border-radius: 4px 4px 4px 4px;
              bottom: -2px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &::before {
            // content: normal;
          }
          &::after {
            // content: normal;
          }
        }
      }
    }

  }
  .tofixed{
    position: fixed;
    background: #fff;
    top: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 50;
  }
</style>
<template>
  <div ref="rightAD" class="right-AD">
    <div v-if="imgUrl">
      <img :src="baseURL+imgUrl" alt="" @click="jump">
    </div>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      imgUrl: '',
      AD: {}
    }
  },
  mounted() {
    if (window.innerHeight < 1000) {
      this.$refs.rightAD.style.transform = "scale(0.7)"; 
      this.$refs.rightAD.style.top = "0"; 
    } else {
      this.$refs.rightAD.style.transform = "scale(1)"; 
      this.$refs.rightAD.style.top = "10%"; 
    }
    window.addEventListener('resize', () => {
      if (window.innerHeight < 1000) {
        this.$refs.rightAD.style.transform = "scale(0.7)"; 
        this.$refs.rightAD.style.top = "0"; 
      } else {
        this.$refs.rightAD.style.transform = "scale(1)"; 
        this.$refs.rightAD.style.top = "10%"; 
      }
    })
  },
  destroyed() {
    window.removeEventListener("resize");
  },
  created() {
    this.getBannerList()
  },
  methods: {
    async jump() {
      if (this.AD.url) {
        await indexApi.imageClick({id: this.AD.id})
        window.open(this.AD.url)
      }
    },
    async getBannerList(){
      let { code, data, message } = await indexApi.findBanner({
        module: '9' // 1: '顶部轮播图',2: '公告轮播图',6: '内页banner',7: '顶部广告位',8: '底部广告位',9: '内页广告位',
      });
      if (code === 200) {
        this.imgUrl = data[0].attachFilePath
        this.AD = data[0]
      }
    }
  },
  computed: {
    ...mapState(["baseURL"])
  }
}
</script>

<style lang="less" scoped>
.right-AD {
  position: fixed;
  right: 0;
  top: 10%;
  width: 126px;
  z-index: 52;
  // box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, .1);
  > div {
    box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
  }
  img {
    width: 126px;
  }
}
</style>
<template>
  <div class="new-footer" ref="newFooter">
    <div class="container">
      <div class="link">
        <span>友情链接</span>
        <ul class="link-list" ref="linkList" id="linkList">
          <li v-for="item of linkList" :key="item.name">
            <a :href="item.link" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
        <span class="more" @click="linkMore">
          {{ index%2===0?'更多':'收起' }} 
          <img v-if="index%2===0" src="@/assets/images/new-ui/ic_sq.svg" alt="">
          <img v-else src="@/assets/images/new-ui/ic_zk.svg" alt="">
        </span>
      </div>
      <div class="footer-nav">
        <div class="left">
          <img class="logo" @click="$router.push('/index')" src="@/assets/images/new-ui/footer_pic_logo@2x.png" alt="">
          <img class="ebs"  @click="goEBS" src="@/assets/images/new-ui/footer_pic_cbs@2x.png" alt="">
        </div>
        <div class="nav">
          <div v-for="item of navList" :key="item.name">
            <h3>{{ item.name }}</h3>
            <ul>
              <li v-for="(obj, index) of item.children" :key="obj.name" @click="goRouter(item.name, index+1)">
                {{ obj.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="tips">© 2022 阳光臻达电子招标投标交易平台版权所有 备案号：沪ICP备19017107号-7 增值电信业务经营许可证：沪B2-20210687</p>
      <p class="tips">技术支持：上海思佩驰科技有限公司</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      linkList: [
        {name: '思佩驰官网', link: 'https://www.88spc.com/'},
        {name: '云南省政府采购网', link: 'http://www.yngp.com/'},
        {name: '云南住房和城乡建设厅', link: 'https://zfcxjst.yn.gov.cn/'},
        {name: '云南省建筑市场服务信息网', link: 'https://www.ynjzjgcx.com/index'},
        {name: '云南省公共资源交易中心', link: 'https://ggzy.yn.gov.cn/homePage'},
        {name: '云南省招投标行业协会', link: 'http://www.ynzbxh.com/'},
        {name: '中国招投标网', link: 'https://www.cecbid.org.cn/'},
        {name: '中国采购与招标网', link: 'https://www.chinabidding.com.cn/'},
        {name: '中国招投标公共服务平台', link: 'http://www.cebpubservice.com/'},
        {name: '中国招投标行业协会', link: 'http://www.ctba.org.cn/'},
      ],
      navList: [
        {
          name: '招采信息',
          children: [
            {name: '工程建设'},
            {name: '采购'},
            {name: '快速招采'}
          ]
        },
        {
          name: '最新资讯',
          children: [
            {name: '平台最新通知'},
            {name: '行业动态'},
            {name: '政策文件'}
          ]
        },
        {
          name: '帮助中心',
          children: [
            {name: '操作手册'},
            {name: '常见问题'}
          ]
        },
        {
          name: '下载专区',
          children: [
            {name: '投标工具下载'},
            {name: '其他下载'}
          ]
        },
        {
          name: '关于我们',
          children: [
            {name: '平台简介'},
            {name: '平台资质'},
            {name: '联系我们'}
          ]
        },
      ]
    }
  },
  methods: {
    goRouter(name, type) {
      if (name === '招采信息') {
        this.$router.push({
          name,
          query: {
            isGov: type,
            type: '全部',
            n: '1'
          }
        })
      } else if (name === '帮助中心') {
        this.$router.push({
          name,
          query: {
            type: type+'',
            contentType: '1'
          }
        })
      } else {
        this.$router.push({
          name,
          query: {
            type: type+''
          }
        })
      }
    },
    goEBS() {
      window.open('https://www.cnca.gov.cn/')
    },
    linkMore() {
      if (this.index%2 === 0) {
        this.$refs.linkList.style.height = '50px'
      } else {
        this.$refs.linkList.style.height = '20px'
      }
      this.index ++
    }
  }
}
</script>

<style lang="less" scoped>
  .new-footer {
    min-height: 342px;
    background: #333;
    color: #fff;
    .link {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 32px;
      border-bottom: 1px solid #585858;
      padding-bottom: 14px;
      >span {
        white-space: nowrap;
      }
      .link-list {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: #C3C3C3;
        height: 20px;
        overflow: hidden;
        margin-top: 2px;
        margin-left: 14px;
        transition: all 0.4s;
        >li {
          margin-right: 10px;
          line-height: 16px;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            color: #F36E31;
          }
        }
      }
      .more {
        color: #F36E31;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 2px;
      }
    }
    .footer-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #585858;
      margin-bottom: 30px;
      .left {
        .logo {
          height: 62px;
          cursor: pointer;
        }
        .ebs {
          height: 135px;
          cursor: pointer;
        }
      }
      .nav {
        display: flex;
        padding: 24px 0;
        >div {
          margin-left: 100px;
          font-size: 14px;
          color: #C3C3C3;
          h3 {
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
          }
          li {
            margin-top: 16px;
            cursor: pointer;
            &:hover {
              color: #F36E31;
            }
          }
        }
        >div:nth-child(1) {
          margin: 0;
        }
      }
    }
    .tips {
      text-align: center;
      color: #999;
      line-height: 28px;
      font-size: 14px;
      span {
        cursor: pointer;
      }
    }
  }
</style>
<template>
  <div class="top-png">
    <ADImg imgModule="7"></ADImg>
  </div>
</template>

<script>
import ADImg from '@/components/ADImg'
export default {
  components: {
    ADImg
  },
  data() {
    return {
    }
  },

}
</script>

<style lang="less" scoped>
.top-png {
  
}
</style>
<template>
 <div class="banner">
  <div id="bgimg" class="bgimg">
    <canvas style="display: none" id="canvas" width="1920" height="168"></canvas>
  </div>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [],
      attachFilePath: '',
      url: '',
      AD: {}
    }
  },
  watch: {
    async '$route.path'() {
      await this.setImgUrl()
      this.$nextTick(() => {
        let img = document.getElementById('img')
        let canvas = document.getElementById('canvas')
        let box = document.getElementById('bgimg')
        let ctx = canvas.getContext('2d')
        img.onload = function () {
          img.style.height = '168px'
          ctx.drawImage(img, 0, 0, 1920, 168)
          img.id = 'img'
          const leftImageData = ctx.getImageData(0, 0, 1, 1);
          const leftPixels = leftImageData.data; // 包含像素颜色信息的一维数组
          const rightImageData = ctx.getImageData(canvas.width-1, 0, 1 , 1);
          const rightPixels = rightImageData.data; // 包含像素颜色信息的一维数组
          box.style.background = `linear-gradient(134deg, rgb(${leftPixels[0]}, ${leftPixels[1]}, ${leftPixels[2]}) 0%, rgb(${rightPixels[0]}, ${rightPixels[1]}, ${rightPixels[2]}) 100%)`
        }
        console.log('this.baseURL + this.attachFilePath', this.baseURL + this.attachFilePath);
        img.src = this.baseURL + this.attachFilePath
        // img.src = 'https://test-dzztb-api.ygzdztb.com/mh/file/images/null/20230220/202302201731239365.jpg'
        img.crossOrigin = "anonymous"
        img.onclick = () => {
          this.jump()
        }
        box.appendChild(img)
      })
    }
  },
  async created() {
    // await this.getBannerList()
    // await this.setImgUrl()
  },
  
  async mounted() {
    await this.getBannerList()
    await this.setImgUrl()
    this.$nextTick(() => {

      let img = new Image()
      let canvas = document.getElementById('canvas')
      let box = document.getElementById('bgimg')
      let ctx = canvas.getContext('2d')
      img.src = this.baseURL + this.attachFilePath
      img.onload = function () {
        img.style.height = '168px'
        ctx.drawImage(img, 0, 0, 1920, 168)
        img.id = 'img'
        const leftImageData = ctx.getImageData(0, 0, 1, 1);
        const leftPixels = leftImageData.data; // 包含像素颜色信息的一维数组
        const rightImageData = ctx.getImageData(canvas.width-1, 0, 1 , 1);
        const rightPixels = rightImageData.data; // 包含像素颜色信息的一维数组
        box.style.background = `linear-gradient(134deg, rgb(${leftPixels[0]}, ${leftPixels[1]}, ${leftPixels[2]}) 0%, rgb(${rightPixels[0]}, ${rightPixels[1]}, ${rightPixels[2]}) 100%)`
      }
      console.log('this.baseURL + this.attachFilePath', this.baseURL + this.attachFilePath);
      // img.src = 'https://test-dzztb-api.ygzdztb.com/mh/file/images/null/20230220/202302201731239365.jpg'
      img.crossOrigin = "anonymous"
      img.onclick = () => {
        this.jump()
      }
      box.appendChild(img)
    })
  },
  methods: {
    async jump() {
      if (this.AD.url) {
        await indexApi.imageClick({id: this.AD.id})
        window.open(this.AD.url)
      }
    },
    setImgUrl() {
      if (this.$route.path.includes('trade-info') || 
          this.$route.path.includes('tender-notice-detail') || 
          this.$route.path.includes('tender-changenotice-detail') || 
          this.$route.path.includes('publicity') || 
          this.$route.path.includes('addendum') ||
          this.$route.path.includes('addendum') ||
          this.$route.path.includes('fast')) {
        this.attachFilePath = this.list[0].attachFilePath
        this.AD = this.list[0]
      } else if (this.$route.path.includes('news')) {
        this.attachFilePath = this.list[1].attachFilePath
        this.AD = this.list[1]
      } else if (this.$route.path.includes('help')) {
        this.attachFilePath = this.list[2].attachFilePath
        this.AD = this.list[2]
      } else if (this.$route.path.includes('download-section')) {
        this.attachFilePath = this.list[3].attachFilePath
        this.AD = this.list[3]
      } else if (this.$route.path.includes('about')) {
        this.attachFilePath = this.list[4].attachFilePath
        this.AD = this.list[4]
      }
      console.log('2');
    },
    async getBannerList(){
      let { code, data, message } = await indexApi.findBanner({
      module: 6 // 1: '顶部轮播图',2: '公告轮播图',6: '内页banner',7: '底部广告位'
      });
      this.list = data;
      console.log('1');
    }
  },
  computed: {
    ...mapState(["baseURL"])
  }
}
</script>

<style lang="less" scoped>
.banner {
  display: flex;
  justify-content: center;
  height: 168px;
  // padding-top: 124px;
  img {
    height: 168px;
    
  }
  .bgimg {
    width: 100%;
    height: 168px;
    display: flex;
    justify-content: center;
  }
  .inside-banner {
    // padding-top: 124px;
    div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .el-carousel {
    .el-carousel__item {
      img {
        height: 168px;
      }
    }
  }
}
</style>